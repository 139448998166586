import React from 'react'

import Hero from '../components/Hero/Hero'
import BenefitsSection from '../sections/Benefits/BenefitsSection'
import EarningsSection from '../sections/EarningsSection'
import OurCausesSection from '../sections/OurCauses/OurCausesSection'
import HelpSection from '../sections/HelpSection'
import Typography from '@material-ui/core/Typography'

const HomeTemplate = ({FAQData, heroImage}) => (
  <article>
    <Typography component="h1" variant="srOnly">
      Página inicial
    </Typography>
    <Hero
      id="hero"
      data-gtm-category="landing_hero"
      data-gtm-main-action="view"
      data-gtm-label="bloco_hero"
      data-gtm-position="bloco_hero_pos1"
      data-gtm-category-ga4="landing_hero"
      data-gtm-main-action-ga4="view"
      data-gtm-label-ga4="block_hero"
      data-gtm-position-ga4="1"
      heroImage={heroImage}
    />
    <BenefitsSection
      id="vantagens"
      data-gtm-category="landing_beneficios"
      data-gtm-main-action="view"
      data-gtm-label="bloco_vantagens"
      data-gtm-position="bloco_vantangens_pos2"
      data-gtm-category-ga4="landing_vantagens"
      data-gtm-main-action-ga4="view"
      data-gtm-label-ga4="block_vantagens"
      data-gtm-position-ga4="2"
    />
    <EarningsSection
      id="ganhos-como-consultora"
      data-gtm-category="landing_ganhos_como_consultora"
      data-gtm-main-action="view"
      data-gtm-label="bloco_ganhos_como_consultora"
      data-gtm-position="bloco_ganhos_como_consultora_pos3"
      data-gtm-category-ga4="landing_lucros_e_ganhos"
      data-gtm-main-action-ga4="view"
      data-gtm-label-ga4="block_lucros_e_ganhos"
      data-gtm-position-ga4="3"
    />
    <OurCausesSection
      id="nossas-causas"
      data-gtm-category="landing_nossas_causas"
      data-gtm-main-action="view"
      data-gtm-label="bloco_nossas_causas"
      data-gtm-position="bloco_nossas_causas_pos4"
      data-gtm-category-ga4="landing_nossos_diferenciais"
      data-gtm-main-action-ga4="view"
      data-gtm-label-ga4="block_nossos_diferenciais"
      data-gtm-position-ga4="4"
    />
    <HelpSection
      id="ajuda"
      data={FAQData}
      data-gtm-category="landing_ajuda"
      data-gtm-main-action="view"
      data-gtm-label="bloco_ajuda"
      data-gtm-position="bloco_ajuda_pos5"
      data-gtm-category-ga4="landing_faq"
      data-gtm-main-action-ga4="view"
      data-gtm-label-ga4="block_faq"
      data-gtm-position-ga4="5"
    />
  </article>
)

export default HomeTemplate
